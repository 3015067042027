@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.CartPage {
    &-Heading {
        color: $black;
        letter-spacing: 0;
        @include desktop {
            font-size: 22px;
        }
    }
    &-TableHead {
        border-radius: 0;
        background-color: $grey10;

        @include from-tablet {
            display: grid;
            padding: 11px var(--content-padding);
            grid-template-columns: minmax(100px, 4fr) 2fr 2fr 30px;
            grid-gap: 0;
        }

        > span:nth-child(2) {
            @include from-tablet {
                text-align: center;
                margin-left: -10px;
            }
        }
    }

    &-CheckoutButton {
        letter-spacing: normal;
        svg {
            display: none;
        }

        &.Button {
            background-color: var(--secondary-button-background);
            border-color: var(--secondary-button-border);
            box-shadow: var(--secondary-button-box-shadow);
            &:not([disabled]) {
                &:hover,
                &:focus {
                    @include desktop {
                        background-color: var(--secondary-button-hover-background);
                        border-color: var(--secondary-button-hover-border);
                    }
                }
            }
        }
    }
    &-Discount {
        border-radius: 0;
        border: 1px solid $grey4;
        input {
            height: 50px;
        }
        .CartPage-ExpandableContentHeading {
            svg path {
                stroke: unset;
            }
            svg:last-of-type path {
                fill: $black;
            }
            span {
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                color: $black;

                letter-spacing: normal;
            }
        }
    }
    .AdvoxFreeDelivery svg {
        margin-left: 0;
    }
    &-ExtraActions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        flex-wrap: wrap;

        @include mobileAndTablet {
            margin-top: 30px;
            padding: 0 var(--content-padding);
        }
    }
    &-AddToShoppingList,
    &-ClearCart {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    &-AddToShoppingList {
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;

        svg {
            margin-right: 11px;
        }
    }
    &-ClearCart {
        svg {
            margin-right: 11px;
            width: 13px;
        }

        button {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: $black;
            cursor: pointer;
        }
    }
}
