@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

[dir='ltr'] .CartItem {
    --qty-button-background: #{$white};
    &-Heading {
        color: $black;
        font-weight: normal;
    }
    &-Wrapper {
        @include from-tablet {
            grid-template-columns: minmax(100px, 4fr) 2fr 2fr 30px;
            grid-gap: 0;
        }
        @include desktop {
            padding: 24px var(--content-padding) 24px 0;
        }
    }
    &-Delete {
        @include from-tablet {
            margin: 0 0 0 auto;
        }
        svg {
            width: 13px;
        }
    }
    &-Qty {
        @include from-tablet {
            margin-right: 0;
        }
    }
    &-ProductActions_isMobileLayout {
        min-width: 320px;
    }
    &-Sku {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: $grey-dark;
        margin: 0;
    }

    &-Price {
        .ProductPrice {
            &-PriceTypeLabel {
                font-weight: normal;
                font-size: 12px;
                line-height: 20px;
                color: $grey-dark;
                margin-right: 4px;
            }
            &-PriceValue,
            &-SubPrice {
                font-size: 15px;
                line-height: 20px;
                color: $black;
                margin-top: 0;
            }
            &-PriceValue {
                font-weight: normal;
            }
            &-SubPrice {
                font-weight: bold;
                margin: 0;
            }
        }

        > span {
            display: flex;
            width: 100%;
            text-align: left;
            justify-content: flex-end;
        }
    }

    &-Quantity span {
        color: $black;
    }

    &-ProductActions {
        align-items: flex-start;
        .CartItem-QuantityWrapper {
            margin: 0 auto;
        }
    }

    .CustomPrice {
        display: block;
        width: auto;
        min-width: unset;
        margin: 0 0 0 auto;

        @include mobile {
            margin: 0 0 -24px;
        }
        &-Label {
            display: block;
            width: 100%;
            max-width: unset;
            min-height: unset;
            font-size: 12px;
            line-height: 16px;
            text-align: left;
            padding: 0 10px;

            &::after {
                transform: rotate(-90deg);
                bottom: -12px;
                right: unset;
                bottom: -10px;
                left: 40px;
                border-width: 8px 8px 8px 0;
            }
        }

        &-Prices {
            padding: 4px;
            min-height: unset;
        }

        &-PriceItem {
            margin: 0 8px;
        }
    }
}
